/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-sequences */
/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { Button, Loader, Modal } from 'lec-ui';
import queryString from 'query-string';

// import * as XLSX from 'xlsx';

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import { Row, Col } from 'react-bootstrap';

import * as act from 'app/actions';
import PanelFilters from 'app/common/filters/panelFiltersContainer';
import PlanCards from './PlanCards';
import ModalAddNewClass from './components/ModalAddNewClass';
import ModalPlanImport from './components/ModalPlanImport';
import UploadConfirmModal from './components/UploadConfirmModal';

import messages from './createPlanMessages';
import './CreatePlanContainer.scss';

import * as createPlanActions from './createPlanActions';
import * as panelActions from '../panel/panelActions';

import excelIcon from 'app/images/icons8-excel.svg';

const CreatePlanContainer = (props) => {
  const {
    intl,
    location,
    getFilterCatalogings,
    getPlansData,
    getCatalogingAbilities,
    getLessonData,
    savePlan,
    restoreToOriginal,
    addPlanTableLesson,
    editPlanTableLesson,
    restorePlanTableLesson,
    movePlanTableLesson,
    deletePlanTableLesson,
    filters,
    lessons,
    filterPeriod,
    idPlan,
    updatedDate,
    history,
    isLoading,
    idPlanSaved,
    hasPlanCustomChanges,
    catalogingAbilities,
    lessonData,
    segmentFilterType,
  } = props;

  const params = queryString.parse(location.search.replace('?', ''));
  const [idSegment, setIdSegment] = useState(null);
  const [idComponent, setIdComponent] = useState(null);
  const [idSegmentSelected, setIdSegmentSelected] = useState(null);
  const [idComponentSelected, setIdComponentSelected] = useState(null);

  const [filterSegment, setFilterSegment] = useState([]);
  const [filterComponent, setFilterComponent] = useState([]);
  const [newFilterPeriod, setNewFilterPeriod] = useState([]);
  const [period, setPeriod] = useState('bimonthly');
  const [auxPeriod, setAuxPeriod] = useState('');
  const [stateIdPlanSaved, setStateIdPlanSaved] = useState(null);

  const [typeCreateModal, setTypeCreateModal] = useState('adding');
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [abilitiesArray, setAbilitiesArray] = useState([]);

  const [idLessonSelected, setIdLessonSelected] = useState(null);
  const [periodDateSelected, setPeriodDateSelected] = useState(null);

  const [arrLessonData, setArrLessonData] = useState([]);

  const [showRestoreOriginalModal, setShowRestoreOriginalModal] = useState(false);

  const [showChangePeriodModal, setShowChangePeriodModal] = useState(false);

  const [lessonsData, setLessonsData] = useState([]);

  const [loading, setLoading] = useState(false);

  // use ref to store the previous value of the lessons
  const prevLessons = useRef(lessons);

  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);

  const [schools, setSchools] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);

  const [showImportModal, setShowImportModal] = useState(false);
  const [uploadConfirm, setUploadConfirm] = useState(false);
  const [messageError, setMessageError] = useState('');

  // useEffect to compare the previous value of lessons with the current value
  useEffect(() => {
    if (prevLessons.current !== lessons) {
      setLessonsData(lessons);
      prevLessons.current = lessons;

      setLoading(false);
    }

    return () => {
      setLessonsData([]);
    };
  }, [lessons]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getFilterCatalogings();
      await getPlansData({
        idPlan: params.idPlan,
        idComponent: params.component,
        period: params.period || 'bimonthly',
        idPlanSaved: params.planSaved,
      });
      setIdSegmentSelected(params.segment);
      setIdComponentSelected(params.component);
    })();

    // clean up on unmount component
    return () => {
      setFilterSegment([]);
      setFilterComponent([]);
    };
  }, []);

  useEffect(() => {
    if (filterSegment.length <= 0 && segmentFilterType) {
      setFilterSegment(filters);
    }
    if (filterSegment.length > 0 && idSegment && !segmentFilterType) {
      setFilterComponent(filters);
    }
  }, [filters]);

  useEffect(async () => {
    setLoading(true);
    if (idSegment && filterSegment.length > 0) {
      await getFilterCatalogings({ idCataloging: idSegment });
      setLoading(false);
    }
  }, [idSegment, filterSegment]);

  useEffect(async () => {
    if (period || idComponent) {
      setLoading(true);
      await getPlansData({
        idPlan: params.idPlan,
        idComponent: idComponent || params.component,
        period: period || 'bimonthly',
        idPlanSaved: params.planSaved,
      });

      if (idComponent) {
        await getCatalogingAbilities({ idCataloging: idComponent });
      }
    }
  }, [period, idComponent]);

  async function handleSavePlan() {
    setLoading(true);
    await savePlan({ idPlan, idPlanSaved: params.planSaved });

    if (params.planSaved) {
      history.push(
        `/class-management?idPlan=${params.idPlan}&planSaved=${params.planSaved}&segment=${
          idSegment || params.segment
        }&component=${idComponent || params.component}&period=${period || 'bimonthly'}`,
      );
    }
    setLoading(false);
  }

  async function handleRestoreToOriginal() {
    setLoading(true);
    await restoreToOriginal({ idPlan });
    await getPlansData({
      idPlan: params.idPlan,
      idComponent: params.component,
      period: params.period || 'bimonthly',
      idPlanSaved: params.planSaved,
    });
    setShowRestoreOriginalModal();
    setLoading(false);
  }

  useEffect(() => {
    if (idPlanSaved) {
      setStateIdPlanSaved(idPlanSaved);
    }
  }, [idPlanSaved]);

  useEffect(() => {
    if (stateIdPlanSaved) {
      history.push(
        `/class-management?idPlan=${params.idPlan}&planSaved=${stateIdPlanSaved}&segment=${
          idSegment || params.segment
        }&component=${idComponent || params.component}&period=${period || 'bimonthly'}`,
      );
    }
  }, [stateIdPlanSaved]);

  useEffect(() => {
    if (filterPeriod.length > 0) {
      const arr = [];
      filterPeriod.map((el) =>
        arr.push({
          value: el,
          label:
            el === 'bimonthly'
              ? intl.formatMessage(messages.bimonthly)
              : intl.formatMessage(messages.quarterly),
        }),
      );

      setNewFilterPeriod(arr);
    }
  }, [filterPeriod]);

  useEffect(() => {
    const arr = [];
    if (catalogingAbilities.length > 0)
      catalogingAbilities.map((el) =>
        arr.push({
          value: el.code,
          label: `${el.code} - ${el.description}`,
        }),
      );
    setAbilitiesArray(arr);
  }, [catalogingAbilities]);

  useEffect(() => {
    if (lessonData) {
      setArrLessonData(lessonData);
    }
  }, [lessonData]);

  async function handleMoveLesson(direction, idLesson, blockPosition) {
    setLoading(true);
    let payload = {
      direction,
      idLesson,
      period,
    };

    if (
      (direction === 'b' && blockPosition === 'l') ||
      (direction === 'a' && blockPosition === 'f')
    ) {
      payload = {
        direction,
        idLesson,
        period,
        blockPosition,
      };
    }

    await movePlanTableLesson(payload);
    await getPlansData({
      idPlan: params.idPlan,
      idComponent: params.component,
      period: params.period || 'bimonthly',
      idPlanSaved: params.planSaved,
    });
  }

  async function handleRemoveLesson(idLesson) {
    setLoading(true);
    await deletePlanTableLesson({ idLesson });
    await getPlansData({
      idPlan: params.idPlan,
      idComponent: params.component,
      period: params.period || 'bimonthly',
      idPlanSaved: params.planSaved,
    });
  }

  async function handleRestoreLesson(idLesson) {
    setLoading(true);
    await restorePlanTableLesson({ idLesson });
    await getPlansData({
      idPlan: params.idPlan,
      idComponent: params.component,
      period: params.period || 'bimonthly',
      idPlanSaved: params.planSaved,
    });
  }

  async function handleCreateClass(where, value, type, periodSelected) {
    setLoading(true);
    setPeriodDateSelected(periodSelected);
    setIdLessonSelected(value);

    if (type === 'adding') {
      setTypeCreateModal('adding');
    } else {
      await getLessonData({ idLesson: value });
      setTypeCreateModal('editing');
    }
    setShowCreateModal(true);
    setLoading(false);
  }

  async function handleSaveAddClass(value, typeModal) {
    setLoading(true);
    if (typeModal === 'editing') {
      await editPlanTableLesson(value);
    } else {
      await addPlanTableLesson(value);
    }
    await getPlansData({
      idPlan: params.idPlan,
      idComponent: idComponent || params.component,
      period: period || 'bimonthly',
      idPlanSaved: params.planSaved,
    });
    setShowCreateModal(false);
  }

  function handleCloseModal() {
    setShowCreateModal(false);
    setArrLessonData([]);
  }

  function handleShowChangePeriodModal(filterPeriodId) {
    setShowChangePeriodModal(true);
    setAuxPeriod(filterPeriodId);
  }

  function handleCancelChangePeriodModal() {
    setShowChangePeriodModal(false);
    setAuxPeriod('');
  }

  async function handleConfirmChangePeriodModal() {
    setLoading(true);
    await restoreToOriginal({ idPlan });
    setPeriod(auxPeriod);
    setShowChangePeriodModal(false);
    setAuxPeriod('');
    setLoading(false);
  }

  const sanitizeFileName = (name) => {
    return name
      .normalize('NFD') // Remove acentos separando caracteres diacríticos
      .replace(/[\u0300-\u036f]/g, '') // Remove os acentos
      .replace(/[^\w.-]/g, '_'); // Substitui caracteres inválidos por "_"
  };

  async function handleExportPlan() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Plano de Aula');

    // Definição das colunas
    worksheet.columns = [
      { header: 'Coleção', key: 'colecao', width: 20 },
      { header: 'Etapa', key: 'etapa', width: 15 },
      { header: 'Ano', key: 'ano', width: 10 },
      { header: 'Componente Curricular', key: 'componente_curricular', width: 25 },
      { header: 'Bimestre', key: 'bimestre', width: 10 },
      { header: 'Trimestre', key: 'trimestre', width: 10 },
      { header: 'Unidade/Capítulo', key: 'unidade_capitulo', width: 30 },
      { header: 'Nome unidade/capítulo', key: 'nome_unidade_capitulo', width: 30 },
      { header: 'Componentes da unidade', key: 'componentes_unidade', width: 30 },
      { header: 'Habilidades trabalhadas', key: 'habilidades_trabalhadas', width: 40 },
      { header: 'Procedimento de checagem', key: 'procedimento_checagem', width: 30 },
      { header: 'Aulas', key: 'aulas', width: 10 },
    ];

    // Função para mapear `values` em um objeto plano
    const mapValuesToRow = (values) => {
      const row = {};
      values.forEach((value) => {
        switch (value.label) {
          case 'Coleção':
            row.colecao = value.value || '';
            break;
          case 'Etapa':
            row.etapa = value.value || '';
            break;
          case 'Ano':
            row.ano = value.value || '';
            break;
          case 'Componente curricular':
            row.componente_curricular = value.value || '';
            break;
          case 'Bimestre':
            row.bimestre = value.value || '';
            break;
          case 'Trimestre':
            row.trimestre = value.value || '';
            break;
          case 'Unidade/Capítulo':
            row.unidade_capitulo = value.value || '';
            break;
          case 'Nome unidade/capítulo':
            row.nome_unidade_capitulo = value.value || '';
            break;
          case 'Componentes da unidade (temática básica trabalhada)':
            row.componentes_unidade = value.value || '';
            break;
          case 'Habilidades trabalhadas':
            row.habilidades_trabalhadas = Array.isArray(value.value)
              ? value.value.join(', ')
              : value.value || '';
            break;
          case 'Procedimentos de checagem':
            row.procedimento_checagem = Array.isArray(value.value)
              ? value.value.join(', ')
              : value.value || '';
            break;
          case 'Aulas':
            row.aulas = value.value || '';
            break;
          default:
            break;
        }
      });
      return row;
    };

    // Filtra `lessonsData` removendo as lições com `removed = 1`
    const filteredLessonsData = lessonsData
      .map((lessonBlock) =>
        Array.isArray(lessonBlock)
          ? lessonBlock.filter((lesson) => lesson.removed !== '1')
          : lessonBlock.removed !== '1'
          ? [lessonBlock]
          : [],
      )
      .filter((lessonBlock) => lessonBlock.length > 0);

    // Percorrer `filteredLessonsData` e adicionar ao Excel
    filteredLessonsData.forEach((lessonBlock) => {
      lessonBlock.forEach((lesson) => {
        if (lesson.values) {
          const row = mapValuesToRow(lesson.values);
          worksheet.addRow(row);
        }
      });
    });

    // Criando nome do arquivo sanitizado
    let segment = filterSegment.find((el) => el.value === idSegmentSelected).label || 'segmento';
    segment = sanitizeFileName(segment.replace(/ /g, '_'));

    let component =
      filterComponent.find((el) => el.value === idComponentSelected).label || 'componente';
    component = sanitizeFileName(component.replace(/ /g, '_').toLowerCase());

    const fileName = sanitizeFileName(
      `plano-${selectedYear}-${segment}-${component}-${idPlan}.xlsx`,
    );

    // Gerando e salvando o arquivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
      new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }),
      fileName,
    );
  }

  useEffect(() => {
    if (selectedYear) {
      sessionStorage.setItem('selectedYear', selectedYear);
    }
  }, [selectedYear]);

  useEffect(() => {
    const year = sessionStorage.getItem('selectedYear');

    const yearsArray = [
      {
        value: year,
        label: year,
      },
    ];
    setYears(yearsArray);

    if (year) {
      setSelectedYear(year);
    }

    const school = sessionStorage.getItem('selectedSchool');
    setSelectedSchool(school);

    if (props.user.idSchools) {
      const uniqueSchools = props.user.idSchools.map((school) => ({
        value: school.id,
        label: school.name,
      }));

      setSchools(uniqueSchools);
    }
  }, []);

  useEffect(() => {
    if (selectedSchool) {
      sessionStorage.setItem('selectedSchool', selectedSchool);
    }
  }, [selectedSchool]);

  async function handleChangeSelectedYear(selectedYearId) {
    setSelectedYear(selectedYearId);
  }

  async function handleChangeSelectedSchool(selectedSchoolId) {
    setSelectedSchool(selectedSchoolId);
  }

  function handleModalImportPlan() {
    setShowImportModal(true);
  }

  async function handleCompleteUpload(updateMessage) {
    setUploadConfirm(true);
    setMessageError(
      updateMessage.length === 0 ||
        (updateMessage.length > 0 && updateMessage[0].status === 3 && updateMessage[0].message),
    );
    setLoading(true);
    await getPlansData({
      idPlan: params.idPlan,
      idComponent: idComponent || params.component,
      period: period || 'bimonthly',
      idPlanSaved: params.planSaved,
    });
    setLoading(false);
  }

  async function handleCloseConfirm() {
    setUploadConfirm(false);
    setShowImportModal(false);
  }

  return (
    <Fragment>
      <Loader show={loading} />
      <Modal
        show={showRestoreOriginalModal}
        icon="pe-7s-attention"
        onClose={() => setShowRestoreOriginalModal(false)}
        title={intl.formatMessage(messages.restoreToOriginal)}
        message={intl.formatMessage(messages.confirmRestoreToOriginal)}
        buttons={[
          {
            label: intl.formatMessage(messages.cancel),
            onClick: () => setShowRestoreOriginalModal(false),
          },
          {
            label: intl.formatMessage(messages.confirm),
            onClick: () => handleRestoreToOriginal(),
          },
        ]}
      >
        {/* {errorDeletedPlan && (
          <Alert variant="danger">{intl.formatMessage(messages.genericError)}</Alert>
        )}
        {deletedPlan && (
          <Alert variant="success">{intl.formatMessage(messages.deletedWithSuccess)}</Alert>
        )} */}
      </Modal>
      <Modal
        show={showChangePeriodModal}
        icon="pe-7s-attention"
        onClose={() => setShowChangePeriodModal(false)}
        title={intl.formatMessage(messages.periodChange)}
        message={intl.formatMessage(messages.confirmRestoreToOriginal)}
        buttons={[
          {
            label: intl.formatMessage(messages.cancel),
            onClick: () => handleCancelChangePeriodModal(),
          },
          {
            label: intl.formatMessage(messages.confirm),
            onClick: () => handleConfirmChangePeriodModal(),
          },
        ]}
      >
        {/* {errorDeletedPlan && (
          <Alert variant="danger">{intl.formatMessage(messages.genericError)}</Alert>
        )}
        {deletedPlan && (
          <Alert variant="success">{intl.formatMessage(messages.deletedWithSuccess)}</Alert>
        )} */}
      </Modal>
      <Row>
        <Col>
          <PanelFilters
            idSegment={idSegment}
            idComponent={idComponent}
            filterSegment={filterSegment}
            filterComponent={filterComponent}
            filterPeriod={newFilterPeriod}
            setIdSegment={(value) => setIdSegment(value)}
            setIdComponent={(value) => setIdComponent(value)}
            setPeriod={(value) => setPeriod(value)}
            handleShowChangePeriodModal={(value) => handleShowChangePeriodModal(value)}
            idSegmentSelected={idSegmentSelected}
            idComponentSelected={idComponentSelected}
            periodSelected={period}
            hasPlanCustomChanges={hasPlanCustomChanges}
            years={years}
            selectedYear={selectedYear}
            setSelectedYear={(value) => handleChangeSelectedYear(value)}
            schools={schools}
            selectedSchool={selectedSchool}
            setSelectedSchool={(value) => handleChangeSelectedSchool(value)}
            disabled
          />
          <div className="cp-container">
            <div>
              <h3 className="cp-title">{intl.formatMessage(messages.title)}</h3>
              <h5>
                {intl.formatMessage(messages.subtitle)}:{' '}
                <span className="text-purple">
                  {params.planSaved
                    ? updatedDate && `${intl.formatMessage(messages.updatedIn)} ${updatedDate}`
                    : intl.formatMessage(messages.underConstruction)}
                </span>
              </h5>
            </div>

            <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
              <Button onClick={() => handleModalImportPlan()} className="btn">
                {intl.formatMessage(messages.importPlan)}
              </Button>
              <Button onClick={() => handleExportPlan()} className="btn">
                {intl.formatMessage(messages.exportPlan)}
                <img src={excelIcon} alt="" srcset="" className="iconExcel" />
              </Button>
              {hasPlanCustomChanges && hasPlanCustomChanges === true && (
                <Button onClick={() => setShowRestoreOriginalModal(true)} className="btn">
                  {intl.formatMessage(messages.restoreToOriginal)}
                </Button>
              )}
              <Button onClick={() => handleSavePlan()} className="btn">
                {intl.formatMessage(messages.continue)}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <PlanCards
            lessons={lessonsData}
            period={period}
            moveLesson={(direction, idLesson, blockPosition) =>
              handleMoveLesson(direction, idLesson, blockPosition)
            }
            removeLesson={(idLesson) => handleRemoveLesson(idLesson)}
            restoreLesson={(idLesson) => handleRestoreLesson(idLesson)}
            createClass={(where, value, type, periodSelected) =>
              handleCreateClass(where, value, type, periodSelected)
            }
          />
        </Col>
      </Row>
      {showCreateModal && (
        <ModalAddNewClass
          closeModal={() => handleCloseModal()}
          typeCreateModal={typeCreateModal}
          period={period}
          abilitiesArray={abilitiesArray}
          lessonData={arrLessonData}
          savePlanClass={(e, typeModal) => handleSaveAddClass(e, typeModal)}
          idLessonSelected={idLessonSelected}
          periodDateSelected={periodDateSelected}
        />
      )}
      <ModalPlanImport
        show={showImportModal}
        onClose={() => setShowImportModal(false)}
        onCompleteUpload={(updateMessage) => handleCompleteUpload(updateMessage)}
        exportPlanTemplate={() => handleExportPlan()}
        period={period}
        idPlan={params.idPlan}
        singularFile={true}
      />
      <UploadConfirmModal
        show={uploadConfirm}
        onClose={handleCloseConfirm}
        messageError={messageError}
      />
    </Fragment>
  );
};

CreatePlanContainer.propTypes = {
  intl: intlShape,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  getFilterCatalogings: PropTypes.func.isRequired,
  getPlansData: PropTypes.func.isRequired,
  savePlan: PropTypes.func.isRequired,
  restoreToOriginal: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(PropTypes.any).isRequired,
  filterPeriod: PropTypes.arrayOf(PropTypes.any),
  lessons: PropTypes.arrayOf(PropTypes.any).isRequired,
  idPlan: PropTypes.any,
  updatedDate: PropTypes.string,
  history: PropTypes.objectOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  deletePlanTableLesson: PropTypes.func.isRequired,
  idPlanSaved: PropTypes.any,
  movePlanTableLesson: PropTypes.func.isRequired,
  restorePlanTableLesson: PropTypes.func.isRequired,
  addPlanTableLesson: PropTypes.func.isRequired,
  hasPlanCustomChanges: PropTypes.bool,
  catalogingAbilities: PropTypes.arrayOf(PropTypes.any).isRequired,
  getCatalogingAbilities: PropTypes.func.isRequired,
  getLessonData: PropTypes.func.isRequired,
  lessonData: PropTypes.arrayOf(PropTypes.any).isRequired,
  editPlanTableLesson: PropTypes.func.isRequired,
  segmentFilterType: PropTypes.bool.isRequired,
};

CreatePlanContainer.defaultProps = {
  intl: [],
  updatedDate: '',
  filterPeriod: [],
  idPlan: null,
  idPlanSaved: null,
  hasPlanCustomChanges: false,
};

const mapStateToProps = ({ createPlan, panel, app }) => ({
  ...createPlan,
  ...panel,
  ...app,
  user: app.user,
});

export default compose(
  withRouter,
  injectIntl,
  connect(
    mapStateToProps,
    act.promisify(
      { ...createPlanActions, ...panelActions },
      {
        include: [
          'getFilterCatalogings',
          'getDashboardTeacher',
          'getCatalogingAbilities',
          'getLessonData',
          'savePlan',
          'restoreToOriginal',
          'addPlanTableLesson',
          'editPlanTableLesson',
          'deletePlanTableLesson',
          'movePlanTableLesson',
          'restorePlanTableLesson',
        ],
      },
    ),
  ),
)(CreatePlanContainer);
