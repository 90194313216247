/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState, useCallback } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import get from 'lodash/get';
import { Modal } from 'lec-ui';
import { api } from 'app/api';

import messages from '../createPlanMessages';
import '../CreatePlanContainer.scss';

function chainPromises(arr) {
  if (!Array.isArray(arr)) return Promise.reject(new Error('Non array passed to each'));
  if (arr.length === 0) return Promise.resolve();
  return arr.reduce((prev, cur) => prev.then(() => cur()), Promise.resolve());
}

const Status = {
  PENDING: 0,
  UPLOADING: 1,
  UPLOADED: 2,
  ERROR: 3,
};

const ModalPlanImport = (props) => {
  const {
    intl,
    show,
    onClose,
    onCompleteUpload,
    singularFile,
    exportPlanTemplate,
    period,
    idPlan,
  } = props;

  const inputFileRef = useRef();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  function uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('direction', 'b');
    formData.append('period', period);
    formData.append('idPlan', idPlan);
    return api
      .post('/teach/importPlanTable.php', formData)
      .then(() => Promise.resolve())
      .catch((err) => {
        console.error('erro no upload', err.response.data);
        const serverErrorMessage = get(err, 'response.data.error');
        const messageError = serverErrorMessage.map((e) => e.error);
        return Promise.resolve(messageError);
      });
  }

  function enqueueFiles(newFiles) {
    const pendingFiles = newFiles.map((file, index) => ({
      file,
      id: index + files.length,
      status: Status.PENDING,
    }));
    const previousFiles = files.some((f) => f.status === Status.ERROR) ? [] : files;
    setFiles(previousFiles.concat(pendingFiles));
    console.log('uploading files...', pendingFiles, previousFiles.concat(pendingFiles));
  }

  async function sendFiles() {
    const pendingFiles = files.filter((c) => c.status === Status.PENDING);
    let currentFiles = files.map((f) => {
      if (f.status === Status.PENDING) {
        return {
          ...f,
          status: Status.UPLOADING,
          message: intl.formatMessage(messages.uploading),
        };
      }
      return f;
    });
    setFiles(currentFiles);
    setLoading(true);
    const promisesFiles = pendingFiles.map(
      (fileContainer) => () =>
        uploadFile(fileContainer.file).then((result) => {
          currentFiles = currentFiles.map((f) => {
            if (f.id === fileContainer.id) {
              return {
                ...f,
                status: result ? Status.ERROR : Status.UPLOADED,
                message: result || intl.formatMessage(messages.uploadedSuccess),
              };
            }
            return f;
          });
          setFiles(currentFiles);
        }),
    );
    try {
      await chainPromises(promisesFiles);
    } finally {
      setLoading(false);
      if (onCompleteUpload) onCompleteUpload(currentFiles);
    }
  }

  useEffect(() => {
    setFiles([]);
    setLoading(false);
  }, [show]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      enqueueFiles(acceptedFiles);
    },
    [enqueueFiles],
  );

  function handleUpload(e) {
    const acceptedFiles = Array.from(e.target.files);
    enqueueFiles(acceptedFiles);
  }

  function getTemplateHref() {
    exportPlanTemplate();
  }

  function handleTriggerUpload() {
    if (loading) return;
    inputFileRef.current.click();
  }

  const { getRootProps } = useDropzone({ onDrop });
  const uploadDisabled = loading || files.every((file) => file.status !== Status.PENDING);

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={intl.formatMessage(messages.ModalPlanImportTitle)}
      icon="ti ti-thumb-up"
      buttons={[
        {
          label: intl.formatMessage(messages.close),
          onClick: onClose,
        },
        {
          label: loading
            ? intl.formatMessage(messages.loadingMessage)
            : intl.formatMessage(messages.continue),
          onClick: () => sendFiles(),
          primary: true,
          disabled: uploadDisabled,
        },
      ]}
    >
      <div className="add-class-modal">
        <div>
          <p>{intl.formatMessage(messages.message)}</p>
        </div>
        <div>
          <a className="btn btn-default" onClick={() => getTemplateHref()}>
            {intl.formatMessage(messages.downloadListTemplate)}
          </a>
        </div>
        <div {...getRootProps()} onClick={handleTriggerUpload} className="upload-border">
          <br />
          <i className="icon icon-small ti-cloud-up" />
          <h6 className="subtitle subtitle-bigger mt-2">
            <strong>{intl.formatMessage(messages.dragAndDropMessage)}</strong>
            <br />
            <span>
              <input
                ref={inputFileRef}
                type="file"
                id="file"
                name="file"
                accept=".xlsx"
                style={{ display: 'none' }}
                onChange={handleUpload}
                disabled={loading}
                multiple={!singularFile}
              />

              {intl.formatMessage(messages.clickHereAlternative)}
            </span>
          </h6>
        </div>
        <div className="table-container">
          <table>
            <tbody>
              {files.map((fileContainer) => (
                <tr
                  className={classNames('text-left', {
                    'text-success': fileContainer.status === Status.UPLOADED,
                    'text-danger': fileContainer.status === Status.ERROR,
                  })}
                >
                  <td>
                    <i className="far fa-file-alt" />
                    {fileContainer.file.name}
                  </td>
                  <td>{fileContainer.message}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

ModalPlanImport.propTypes = {
  intl: intlShape.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCompleteUpload: PropTypes.func.isRequired,
  onManuallyUpload: PropTypes.func.isRequired,
  singularFile: PropTypes.bool,
  isStudent: PropTypes.bool.isRequired,
  schools: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedSchool: PropTypes.shape({}).isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
};

ModalPlanImport.defaultProps = {
  singularFile: false,
};

export default compose(injectIntl)(ModalPlanImport);
