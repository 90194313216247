/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { Modal } from 'lec-ui';

import messages from '../createPlanMessages';

const UploadConfirmModal = (props) => {
  const { intl, show, onClose, messageError } = props;
  const error = messageError.toString().replace(',', '<br/>');

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={intl.formatMessage(messages.planUploaded)}
      subtitle={
        messageError ? (
          messageError.toString() !== 'true' ? (
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage(messages.uploadListError, {
                  error,
                }),
              }}
            />
          ) : (
            intl.formatMessage(messages.uploadListErrorGeneric)
          )
        ) : (
          intl.formatMessage(messages.uploadPlanSuccessfull)
        )
      }
      buttons={[
        {
          label: intl.formatMessage(messages.continue),
          onClick: onClose,
        },
      ]}
    />
  );
};

UploadConfirmModal.propTypes = {
  intl: intlShape.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  messageError: PropTypes.string.isRequired,
};

export default compose(injectIntl)(UploadConfirmModal);
